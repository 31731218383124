import { graphql, PageProps } from "gatsby"
import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Maybe, MicrocmsNews, Query } from "../../../types/graphql-types"
import PageTitle from "../../components/utils/PageTitle"
import { format } from "date-fns"
import "../../components/News/style.css"
import { Link } from "gatsby"
import { ja_description } from "../../components/const"

const IsEmptyData = (data: Maybe<MicrocmsNews> | undefined) => {
  return !!data && !!data.title && !!data.body && !!data.date
}

const NewsDetailPage: React.FC<PageProps<Query>> = ({ data, location }) => {
  const NewsData = data.microcmsNews
  if (IsEmptyData(NewsData)) {
    return (
      <Layout selectPageName="News" location={location}>
        <SEO
          title={"News | " + `${NewsData?.title}`}
          description={ja_description}
          lang={"ja"}
          twitterTitle={"News"}
        />
        <PageTitle title={"News"} />
        <div className="news-contents-wrapper">
          <div className="news-contents-date-title-wrapper">
            <div className="news-contents-date">
              <p>
                {NewsData?.date
                  ? format(new Date(NewsData.date), "yyyy.MM.dd")
                  : "日付が指定されていません"}
              </p>
            </div>
            <div className="news-contents-title">
              <p>{NewsData?.title ? NewsData.title : "non-title"}</p>
            </div>
          </div>
          <div className="news-contents-body">
            <div
              dangerouslySetInnerHTML={{
                __html: `${data.microcmsNews?.body}`
              }}
            />
          </div>
          <div className="font-size-12 margin-top-30">
            <Link to="/news" className="back-to-news-list">
              <p>一覧に戻る</p>
            </Link>
          </div>
        </div>
      </Layout>
    )
  }
  return (
    <Layout selectPageName="News" location={location}>
      <div>お探しのニュースは存在しません</div>
    </Layout>
  )
}
export default NewsDetailPage

export const microcmsNewsItemQuery = graphql`
  query MicrocmsNewsItemQuery($id: String!) {
    microcmsNews(id: { eq: $id }) {
      body
      date
      title
    }
  }
`
